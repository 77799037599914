@import 'index-mixins';

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  @include maxMobile {
    transform: translateY(-5%);
  }
}

.video {
  height: 100%;
  object-fit: cover;

  @include maxMobile {
    height: 120%;
  }
}

.poster {
  width: unset;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);

  @include maxMobile {
    height: 120%;
    top: unset;
    bottom: -10%;
  }
}
