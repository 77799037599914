@import 'index-mixins';

.root {
  position: relative;
  z-index: 0;
}

.cardsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  @include mobile {
    grid-template-columns: none;
    grid-template-rows: auto;
    grid-gap: 16px;
  }
}

.caption {
  @include title;
  margin-bottom: 48px;
}
