@import 'index-mixins';

.ellipseAnimation {
  transform: matrix(1, 0, 0, 1, 0, 0);
  animation-duration: 4s;
  animation-name: ellipse-animation;
  animation-timing-function: steps(2, cubic-bezier(0.45, 0.26, 0.56, 0.74));
  animation-iteration-count: infinite;
}

.ellipse {
  transform: rotate(54deg);
}

@keyframes ellipse-animation {
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }

  50% {
    transform: matrix(0.98, -0.02, 0, 1, 0, 0);
  }

  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}
