.bridgerPaymentButton {
  height: auto;
  padding: 16px 24px;
  border: none;
}
.bridgerPaymentButton > div {
  font-family: 'Inter';
  font-weight: 500;
  line-height: 24px;
}
