@import 'index-mixins';

.container {
  position: relative;
  width: 100%;
  height: 440px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 80px;

  @include maxTablet {
    height: 100%;
    transform: unset;
    padding-top: 0;
  }

  @include maxMobile {
    padding-top: 0;
    height: 280px;
    transform: translateY(-20px);
  }
}

.videoWrapper {
  position: relative;
  max-width: 996px;
  width: 100%;
  height: 100%;
  border-radius: 32px;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(105, 48, 226, 0.3), rgba(105, 48, 226, 0.3)),
      linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    background-blend-mode: multiply, normal, normal, normal;
  }

  @include mobile {
    border-radius: 20px;
  }
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.poster {
  border-radius: 32px;

  @include mobile {
    border-radius: 20px;
  }
}
