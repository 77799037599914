@import 'index-mixins';

.item {
  @include loadingSkeleton();

  border-radius: 8px;
}

.darkItem {
  @include darkLoadingSkeleton;
}

.withoutAnimation {
  animation: none;

  &::before {
    animation: none;
  }
}
