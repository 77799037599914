@import 'mixins';

.paymentTimer {
  height: 48px;
  background-image: linear-gradient(92deg, #f384bf, #93b4fe 64%, #7feff7);
  @include shadow-small();
}

.container,
.row {
  height: 100%;
}

.timerWrapper {
  display: flex;
  height: 100%;
  align-items: center;
  color: $colorWhite;
}

.timer {
  color: $colorWhite;
  display: inline-block;
}
