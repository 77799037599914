@import 'index-mixins';

.formContainer,
.totalContainer,
.submitContainer,
.cancelButton {
  margin-top: 24px;
}

.totalContainer {
  color: $colorBlack;
  @include font-subtitle;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
}

.title {
  margin: 0 0 6px 0;
  @include font-title-3;
  color: $colorBlack;
}

.description {
  margin: 0 0 24px 0;
  color: $colorGreyMedium;
  @include font-base;

}
