@import 'index-mixins';

.root {
  z-index: 0;
  display: flex;
  box-sizing: border-box;
  height: 56px;
  margin: 40px auto 28px;
  padding: 4px;
  background-color: rgba(var(--color-white--rgb), 0.1);
  border-radius: 16px;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);

  @include mobile {
    width: 100%;
  }
}

.white {
  color: white;
}

.black {
  color: black;
}

.link {
  @include font-large;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 32px;
  color: var(--color-white);
  font-weight: 500;
  font-family: Inter, sans-serif;
  text-decoration: none;
  background-color: rgba(var(--color-white--rgb), 0);
  border-radius: 12px;
  transition: background-color $durationMedium1, color $durationMedium1;

  &:hover {
    background-color: rgba(var(--color-white--rgb), 0.1);
  }

  & + & {
    margin-left: 8px;
  }

  @include mobile {
    flex-basis: 50%;
    flex-grow: 1;
  }
}

.link.linkActive {
  color: var(--color-black);
  background-color: var(--color-white);
}

.icon {
  display: flex;
  align-items: center;
  margin-right: 8px;
}
