@import 'index-mixins';

.root {
  position: relative;
  z-index: 0;
  flex-grow: 1;
  box-sizing: border-box;
  height: 420px;
  padding: 32px;
  overflow: hidden;
  background: linear-gradient(
      45deg,
      rgba(109, 210, 229, 0.1) 0%,
      rgba(123, 160, 235, 0.1) 21%,
      rgba(188, 134, 246, 0.1) 43.72%,
      rgba(220, 196, 248, 0.1) 76.2%,
      rgba(191, 181, 198, 0.1) 100%
    ),
    var(--color-black);
  border-radius: 32px;

  @include mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
  }
}

.rootWide {
  display: flex;
  flex-direction: column;
  grid-column: span 2;
  justify-content: center;
  width: 100%;
  height: 360px;

  @include mobile {
    grid-column: auto;
    justify-content: flex-start;
    height: 420px;
  }
}

.title {
  z-index: 1;
  margin: 0;
  padding: 0;
  color: var(--color-white);
  font-weight: 700;

  @include font-title-1();

  @include mobile {
    @include font-title-2;
  }
}

.description {
  @include description;

  z-index: 1;
  margin: 12px 0 0;
}

.backgroundImage,
.backgroundVideo {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center center;
  background-size: cover;
  transform: translate(-50%, -50%);
}

.backgroundImage {
  @include mobile {
    display: none;
  }
}

.backgroundImageMobile {
  display: none;

  @include mobile {
    display: block;
  }
}
