@import 'mixins';
@import 'animations';

body,
html {
  margin: 0;
  padding: 0;
  background: black;
}

@mixin title {
  margin: 0;
  padding: 0;
  color: var(--color-white);
  font-weight: 300;
  font-size: 72px;
  font-family: 'Koh Santepheap', sans-serif;
  line-height: 80px;
  text-align: center;

  b {
    font-weight: 900;
    font-family: Inter, sans-serif;
  }

  @include mobile {
    font-size: 44px;
    line-height: 44px;
  }
}

@mixin description {
  @include font-subtitle();

  max-width: 394px;
  margin: 0;
  padding: 0;
  color: var(--color-white);
  font-weight: 400;

  @include mobile {
    @include font-large();
  }
}

@mixin card {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 24px;
  color: var(--color-black);
  text-decoration: none;
  background-color: var(--color-white);
  border-radius: 12px;

  @include shadow-medium;

  @include mobile {
    padding: 16px;
    border-radius: 16px;
  }
}

@mixin pageHeader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  margin-top: 44px;
  margin-bottom: 20px;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
    margin-top: 24px;
    margin-bottom: 16px;
  }
}

@mixin darkModal {
  background-color: var(--color-black);
}

@mixin modalCloser {
  top: 32px;
  right: 32px;
  background-color: var(--color-grey-light);
  border-radius: 50%;
  transition: fill $durationShort1, background-color $durationShort1;

  svg {
    fill: var(--color-grey-medium);
  }

  &:hover {
    background-color: var(--color-grey);

    svg {
      fill: var(--color-grey-dark);
    }
  }

  @include mobile {
    right: 16px;
  }
}

@mixin darkModalCloser {
  @include modalCloser;

  background-color: var(--color-grey-dark);

  svg {
    fill: var(--color-grey-medium);
  }

  &:hover {
    background-color: var(--color-grey-dark-hover);

    svg {
      fill: var(--color-grey-medium);
    }
  }
}

@mixin darkInput {
  // по хорошему надо настроить style-loader и подгружать файлы с переменными динамически

  --input-color: var(--color-white);
  --input-background: var(--color-grey-dark);
  --input-border-color: transparent;
  --input-hover-background: var(--color-grey-dark-hover);
  --input-focus-background: var(--color-grey-dark);
  --input-focus-hover-background: var(--color-grey-dark-hover);
  --input-hover-border-color: transparent;
  --input-placeholder-color: var(--color-grey-medium);
  --input-title-color: var(--color-grey-medium);
  --input-title-disabled-color: var(--color-grey-medium);
  --input-disabled-color: var(--color-grey-medium);
  --input-disabled-background: var(--color-grey-dark);
  --input-disabled-border-color: transparent;
}

@mixin modalButton {
  flex-basis: 100%;
  flex-grow: 1;

  @include mobile {
    flex-basis: auto;
  }

  & + & {
    margin-left: 12px;

    @include mobile {
      margin-bottom: 12px;
      margin-left: 0;
    }
  }
}

@mixin headerTitle {
  color: var(--color-black);
  font-weight: 700;

  @include font-title-2;
}

@mixin cardTitle {
  @include font-subtitle;

  color: var(--color-black);
  font-weight: 700;
}

@mixin headerButton {
  @include mobile {
    margin-top: 16px;
  }
}

@mixin darkButton {
  color: var(--color-white);
  background-color: var(--color-black);
  background-image: none;
  border-color: transparent;
  transition: background-color $durationShort1;

  svg {
    fill: var(--color-white);
  }

  &:hover {
    color: var(--color-white);
    background-color: var(--color-grey-dark);
    background-image: none;
    border-color: transparent;

    svg {
      fill: var(--color-white) !important;
    }
  }
}

@mixin darkLoadingSkeleton {
  position: relative;
  display: inline-block;
  overflow: hidden;
  line-height: 1;
  background: #212121;
  border-radius: var(--skeleton-border-radius);

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + var(--skeleton-gradient-width));
    height: 100%;
    background-image: linear-gradient(
      88.26deg,
      rgba(255, 255, 255, 0) 1.48%,
      rgba(255, 255, 255, 0.2) 33.83%,
      rgba(255, 255, 255, 0) 98.52%
    );
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: var(--skeleton-gradient-width) 100%;
    transform: translateX(-100%);
    animation-name: skeleton-progress;
    animation-duration: var(--skeleton-animation-duration);
    animation-timing-function: var(--skeleton-animation-timing-function);
    animation-iteration-count: infinite;
    content: '';
  }
}
