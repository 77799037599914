@import 'index-mixins';

$tabProgressDuration: 8000;
$fadeDuration: 400;

.loyaltySection {
  width: 100%;
  margin-top: 164px;

  @include mobile {
    margin-top: 132px;
  }
}

.container {
  position: relative;
  width: 100%;
}

.media {
  position: relative;
  width: 100%;
  height: 560px;
  margin-bottom: -150px;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba($colorBlackRGB, 0) 45.5%, $colorBlack 89.16%);
  }

  @include maxTablet {
    height: 400px;
    margin-bottom: -120px;
  }

  @include maxMobile {
    height: 260px;
    margin-bottom: -80px;

    &::after {
      content: '';
      position: absolute;
      left: calc((100% - 100vw) / 2);
      bottom: 0;
      top: unset;
      width: 100vw;
      height: 180px;
      background: linear-gradient(180deg, rgba($colorBlackRGB, 0) 38.28%, $colorBlack 89.16%);
    }
  }
}

.poster {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.posterHidden {
  display: none;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 32px;

  @include mobile {
    grid-gap: 12px;
  }
}

.headingWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 20px;

  @include mobile {
    grid-gap: 12px;
  }
}

.title {
  @include title;

  text-align: center;

  @include mobile {
    font-size: 36px;

    b {
      display: block;
    }
  }
}

.description {
  @include description;

  font-family: Inter, sans-serif;
  color: $colorGrey;
  text-align: center;
  max-width: 480px;
  min-height: 56px;

  @include mobile {
    max-width: 394px;
  }
}

.descriptionBonuses {
  max-width: 350px;
}

.tabList {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 24px;

  @include mobile {
    flex-wrap: wrap;
  }
}

.tab {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  font-family: Inter, sans-serif;
  font-size: 12px;
  line-height: 1.2;
  background-color: rgba($colorWhiteRGB, 0.2);
  border: none;
  backdrop-filter: blur(2.5px);
  padding: 0 16px;
  border-radius: 30px;
  cursor: pointer;
  text-transform: capitalize;
  overflow: hidden;

  span {
    color: $colorWhite;
    mix-blend-mode: difference;
  }
}

.tabActive {
  z-index: 1;
  overflow: hidden;
  -webkit-transform: translateZ(0);
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: linear-gradient(to right, $colorWhite 100%, transparent 0);
    will-change: transform;
    transform: translate3d(0, 0, 0);
    transition: transform 0.2s, clip-path 0.2s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: visible;
    -webkit-perspective: 0;
    animation: progress #{$tabProgressDuration}ms linear;
  }

  @include maxMobile {
    &::before {
      border-radius: 30px;
      /*
        В моб. Safari есть проблемы со свойствами `backface-visibility`, `overflow`,
        поэтому для мобильных устр. используем `clip-path` для анимации.
       */
      animation: progressClipPath #{$tabProgressDuration}ms linear;
    }
  }
}

.tabWithoutAnimation {
  &::before {
    animation: unset;
  }
}

.button {
  min-width: 224px;

  @include mobile {
    width: 100%;
    max-width: 345px;
    margin-top: 12px;
  }
}

.enter {
  opacity: 0.01;
  transition: opacity #{$fadeDuration}ms ease-in;
}
.enter.enterActive {
  opacity: 1;
}
.leave {
  opacity: 1;
  transition: opacity #{$fadeDuration}ms ease-in;
}
.leave.leaveActive {
  opacity: 0.01;
}
.appear {
  opacity: 0.01;
}
.appear.appearActive {
  opacity: 1;
  transition: opacity #{$fadeDuration}ms ease-in;
}

@keyframes progress {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes progressClipPath {
  0% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

:export {
  tabProgressDuration: $tabProgressDuration;
  fadeDuration: $fadeDuration;
}
