@import 'index-mixins';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.video {
  width: 100%;
  object-fit: cover;

  @include maxTablet {
    width: 100%;
  }

  @include maxMobile {
    width: unset;
    height: 100%;
  }
}

.poster {
  composes: video;
  left: 50%;
  transform: translateX(-50%);
}
