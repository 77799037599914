@import 'index-mixins';

.headerContainer {
  position: absolute;
  width: 100%;
  height: 72px;
}

.header {
  top: 0;
  background: none;
}

.content {
  position: relative;
  min-height: 100vh;
  margin-bottom: 0;
  overflow-x: hidden;
  background-color: var(--color-black);
}

.isPageScrolled {
  position: absolute;
  top: 0;
  width: 100%;
  height: 0;
  visibility: hidden;
  user-select: none;
  pointer-events: none;
}

.banner {
  position: fixed;
}

body,
input,
button {
  @include font;
}
