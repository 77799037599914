@import 'index-mixins';

/* stylelint-disable */
:root {
  --solar-card-background-dark: #1a1a1a;

  --color-transparent: transparent;
  --color-transparent-hover: rgba(1, 171, 251, 0.1);

  --color-red-hover: #cc4739;

  --color-purple-dark: #20144b;
  --color-purple-smoke: #e7e3fc;
  --color-purple-light: #e1dcfc;
  --color-purple-medium: #c4bbf9;
  --color-purple: #674ff1;
  --color-purple-hover: #4b3c9e;

  --color-grey-dark-hover: #474747;

  --color-green-light: #dcfcd8;

  --color-black: black;

  --color-orange: #ff9b00;

  --color-solar-yellow-light: #fff8db;
  --color-yellow: var(--color-purple);
  --color-yellow-hover: var(--color-purple-hover);

  --color-blue: var(--color-purple);
  --color-blue-hover: var(--color-purple-hover);

  --shadow-medium: 0 0 8px rgba(199, 199, 199, 0.05);

  --select-border-radius: 8px;
  --select-overlay-border-radius: 12px;
  --select-overlay-padding: 4px 0;
  --select-background: var(--color-grey-smoke);
  --select-border-color: var(--color-grey-smoke);
  --select-hover-background: var(--color-grey-light);
  --select-hover-border-color: var(--color-grey-light);

  --input-border-radius: 8px;

  --button-border-radius: 8px;
  --button-padding: 0 12px;
  --button-active-background: var(--color-black);
  --button-active-hover-background: var(--color-black);
  --button-active-border-color: transparent;
  --button-active-hover-border-color: transparent;
  --button-active-color: var(--color-white);
  --button-background: #e2e2e2;
  --button-border-color: transparent;
  --button-hover-background: var(--color-grey);
  --button-hover-border-color: transparent;
  --button-primary-background: linear-gradient(
      45.67deg,
      #eed7f1,
      #fefcfe 8.38%,
      #dbc1d4 18.93%,
      #6dd2e5 28.21%,
      #7ba0eb 39.54%,
      #bc86f6 50.38%,
      #dcc4f8 62.46%,
      #bfb5c6 73.89%,
      #f5f4f6 86.05%,
      #d7cace 98.86%
    )
    60% -1px / calc(128% + 2px) calc(100% + 2px);
  --button-primary-hover-background: linear-gradient(
      45.67deg,
      #eed7f1,
      #fefcfe 8.38%,
      #dbc1d4 18.93%,
      #6dd2e5 28.21%,
      #7ba0eb 39.54%,
      #bc86f6 50.38%,
      #dcc4f8 62.46%,
      #bfb5c6 73.89%,
      #f5f4f6 86.05%,
      #d7cace 98.86%
    ) -1px / calc(128% + 2px) calc(100% + 2px);
  --button-primary-border-color: var(--color-transparent);
  --button-primary-hover-border-color: var(--color-transparent);
  --button-primary-color: var(--color-black);
  --button-primary-hover-color: var(--color-black);

  --button-base-background: var(--color-grey-light);
  --button-base-hover-background: var(--color-grey-smoke);
  --button-base-border-color: var(--color-grey-light);
  --button-base-hover-border-color: var(--color-grey-smoke);

  --button-secondary-background: var(--color-purple-smoke);
  --button-secondary-hover-background: var(--color-purple-light);
  --button-secondary-border-color: var(--color-purple-smoke);
  --button-secondary-hover-border-color: var(--color-purple-light);
  --button-secondary-color: var(--color-purple);
  --button-secondary-hover-color: var(--color-purple);

  --checkbox-active-icon-color: var(--color-white);
  --checkbox-background: var(--color-grey-smoke);
  --checkbox-border-color: var(--color-grey-smoke);
  --checkbox-hover-background: var(--color-grey-light);
  --checkbox-hover-border-color: var(--color-grey-light);

  --rating-with-text-accented-background: #ffd41e;

  --star-rating-accented-icon-color: #ffd41e;

  --search-filter-border-radius: 8px;

  --radio-button-group-border-radius: 8px;
  --radio-active-point-color: var(--color-white);
  --radio-button-group-background: var(--button-background);
  --radio-button-group-hover-background: var(--button-hover-background);
  --radio-button-group-border-color: transparent;
  --radio-button-group-hover-border-color: transparent;
  --radio-button-group-selected-background: var(--color-black);
  --radio-button-group-selected-border-color: var(--color-black);
  --radio-button-group-selected-color: var(--color-white);

  // --dropdown-background: var(--color-transparent);
  // --dropdown-background-hover: var(--color-grey-light);
  // --dropdown-border-color: var(--color-transparent);
  // --dropdown-border-color-hover: var(--color-grey-light);

  --travellers-children-child-background: var(--color-black);
  --travellers-children-child-color: var(--color-white);
  --travellers-border-radius: 8px;

  --search-form-submit-button-desktop-width: 100px;

  --hotels-search-form-guests-background: var(--color-black);
  --hotels-search-form-guests-color: var(--color-white);

  --datepicker-overlay-border-radius: 8px;

  --datepicker-day-hover-background: var(--color-purple-light);
  --datepicker-day-background-hover: var(--color-purple-light);
  --datepicker-day-color: var(--color-black);
  --datepicker-day-color-hover: var(--color-white);

  --datepicker-day-holiday-color: var(--color-black);
  --datepicker-day-holiday-hover-color: var(--color-black);
  --datepicker-day-holiday-font-weight: normal;

  --datepicker-day-active-background: var(--color-purple);
  --datepicker-day-active-background-hover: var(--color-purple-hover);
  --datepicker-day-active-color: var(--color-white);
  --datepicker-day-active-hover-color: var(--color-white);
  --datepicker-day-active-holiday-color: var(--color-grey-medium);
  --datepicker-day-active-holiday-hover-color: var(--color-white);

  --datepicker-day-disabled-background: var(--color-transparent);
  --datepicker-day-disabled-color: var(--color-grey);

  --datepicker-header-option-color: var(--color-black);
  --datepicker-header-option-hover-color: var(--color-black);
  --datepicker-header-option-background: var(--color-grey-light);
  --datepicker-header-option-hover-background: var(--color-grey);
  --datepicker-header-option-border-color: var(--color-grey-light);
  --datepicker-header-option-hover-border-color: var(--color-grey);

  --datepicker-header-selected-option-color: var(--color-white);
  --datepicker-header-selected-option-hover-color: var(--color-white);
  --datepicker-header-selected-option-background: var(--color-black);
  --datepicker-header-selected-option-hover-background: var(--color-black);
  --datepicker-header-selected-option-border-color: var(--color-black);
  --datepicker-header-selected-option-hover-border-color: var(--color-black);

  --datepicker-header-border-radius: 8px;

  --modal-layout-overlay-background: rgba(0, 0, 0, 0.5);

  --modal-content-border-radius: 20px;

  --modal-content-small-width: 343px;
  --modal-content-medium-width: 384px;
  --modal-content-wide-width: 588px;

  --reservation-banner-color: var(--color-white);
  --reservation-banner-background: linear-gradient(97deg, #f384bf, #93b4fe 64%, #7feff7);

  --reservation-banner-button-color: var(--color-black);
  --reservation-banner-button-background: rgba(33, 33, 33, 0.1);
  --reservation-banner-button-hover-background: rgba(33, 33, 33, 0.2);

  --reservation-banner-cross-icon-fill: var(--color-white);
  --reservation-banner-cross-icon-hover-fill: var(--color-grey-light);

  --travellers-with-classes-color: var(--color-white);
  --travellers-with-classes-background: var(--color-black);
  --travellers-with-classes-border-color: var(--color-black);

  --plus-minus-color: var(--color-black);
  --plus-minus-background: var(--button-background);
  --plus-minus-hover-background: var(--color-grey);
  --plus-minus-border-color: var(--button-background);
  --plus-minus-hover-border-color: var(--button-hover-background);

  --plus-minus-disabled-background: var(--button-disabled-background);
  --plus-minus-disabled-border-color: var(--button-disabled-background);

  --flight-border-radius: 8px;
  --flight-desktop-field-width: calc((100% - 48px - 10px - 92px) / 4);
  --flight-tablet-field-width: calc((100% - 48px) / 2);

  --flight-datepicker-desktop-field-width: calc((100% - 48px - 10px - 92px) / 4);
  --flight-datepicker-tablet-field-width: calc((100% - 10px - 237px) / 2);

  --flight-add-route-desktop-field-width: calc((100% - 16px) / 3);
  --flight-add-route-tablet-field-width: calc((100% - 16px) / 3);

  --flight-complex-search-button-desktop-width: calc((100% - 16px) / 3);

  --travellers-with-classes-border-radius: 8px;
  --travellers-with-classes-desktop-width: calc((100% - 48px - 10px - 92px) / 4);
  --travellers-with-classes-tablet-width: calc((100% - 10px - 237px) / 2);

  --travellers-with-classes-complex-route-desktop-width: calc((100% - 16px) / 3);
  --travellers-with-classes-complex-route-tablet-width: calc((100% - 16px) / 3);

  --service-classes-color: var(--color-black);
  --service-classes-background: var(--color-white);
  --service-classes-border-color: var(--color-grey-light);
  --service-classes-border-radius: 2px;

  --service-classes-selected-color: var(--color-white);
  --service-classes-selected-background: var(--color-black);

  --loading-dots-background: var(--color-purple);

  --hotel-gallery-big-image-border-radius: 8px;
  --hotel-gallery-small-image-border-radius: 4px;

  --hotel-info-block-border-radius: 8px;
  --hotel-info-narrow-padding: 24px;
  --hotel-info-padding: 24px;
  --hotel-info-narrow-mobile-padding: 16px;
  --hotel-info-mobile-padding: 16px;
  --hotel-info-box-shadow: none;
  --hotel-info-wrapper-background: transparent;

  --image-loader-border-radius: 8px;

  --credit-card-container-box-shadow: none;

  --cookie-policy-decline-button-border-color: transparent;

  --message-screen-border-offset: 40px;

  --cookie-policy-root-background-color: #212121;
  --cookie-policy-root-box-shadow: 0px 4px 20px rgba(64, 64, 64, 0.1);
  --cookie-policy-root-border-radius: 16px;

  @include mobile {
    --image-loader-border-radius: 0;
    --hotel-gallery-big-image-border-radius: 0;
    --hotel-gallery-small-image-border-radius: 0;
  }
}
/* stylelint-enable */
