@import 'index-mixins';

.NotFoundPage {
  position: relative;
  padding-top: 120px;
  height: 600px;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  color: $colorWhite;
  font-family: Koh Santepheap, sans-serif;
  font-size: 72px;
  line-height: 82px;
  text-align: center;
  width: 540px;
  margin: 0 auto;
  animation: fade-in 2s ease-in;
  z-index: 1;

  @include mobile {
    width: 340px;
    font-size: 44px;
    line-height: 50px;
  }

  .code {
    font-family: Inter, sans-serif;
    font-weight: 900;
  }

  .homeButton {
    margin-top: 50px;
    font-family: Inter, sans-serif;
    font-weight: 400;

    @include mobile {
      margin-top: 32px;
    }
  }
}

.ellipseContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: fade-in 2s ease-in;
}

.ellipse {
  width: 540px;
  opacity: 0.4;

  @include mobile {
    width: 370px;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
