@import 'mixins';

.PaymentAgreementInfo {
  @include font-small;
  padding: 16px 0;

  color: $colorGreyMedium;
  text-align: center;

  a {
    color: $colorBlue;
    text-decoration: none;
    &:hover {
      color: $colorBlueHover;
    }
  }
}

.hidden {
  display: none;
}
