@import 'index-mixins';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 164px;

  @include mobile {
    flex-direction: column;
  }
}

.infoBlock {
  width: 486px;

  @include mobile {
    width: 100%;
  }
}

.title {
  @include title;

  text-align: left;

  @include mobile {
    text-align: center;
  }
}

.description {
  @include description;

  margin-top: 24px;

  @include mobile {
    margin: 16px auto 0;
    text-align: center;
  }
}

.buttons {
  display: flex;
  margin-top: 48px;

  @include mobile {
    position: absolute;
    bottom: 36px;
    flex-direction: column;
  }
}

.button {
  text-decoration: none;
  cursor: pointer;

  & + & {
    margin: 0 0 0 12px;
  }

  @include mobile {
    & + & {
      margin: 12px 0 0 0;
    }
  }

  g {
    transition: opacity $durationMedium1;
  }

  &:hover {
    g {
      opacity: 0.3;
    }
  }
}

.image {
  width: 428px;
  height: 528px;
  object-fit: cover;
  object-position: top;
  border-radius: 45px 45px 0 0;
  user-select: none;

  @include mobile {
    width: 284px;
    height: 484px;
  }
}

.imageShadow {
  display: flex;
  justify-content: center;
  width: 100%;
  box-shadow: inset 0 -30px 10px -10px black;
}

.imageGradientContainer {
  position: relative;
  top: 0;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 434px;
  height: 100%;
  padding: 2px 0 0;
  overflow: hidden;
  border-radius: 45px 45px 0 0;

  @include mobile {
    width: 288px;
    margin: 48px auto 0;
  }

  &::before {
    position: absolute;
    top: -50%;
    left: -50%;
    z-index: -2;
    width: 200%;
    height: 200%;
    background-color: #000;
    background-image: linear-gradient(
      210.66deg,
      #6dd2e5 18.68%,
      #7ba0eb 26.7%,
      #bc86f6 34.57%,
      #dcc4f8 48.03%,
      #f5f4f6 60.86%,
      #bfb5c6 89.96%
    );
    background-repeat: no-repeat;
    background-position: 0 0, 0 100%;
    background-size: 50% 50%, 50% 50%;
    filter: blur(37px);
    animation: rotate 8s linear infinite;
    animation-name: rotate;
    content: '';
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
