.content {
  min-height: 100vh;
  background-color: var(--color-grey-smoke);
}
.payFormContainer {
  margin-top: 48px;
}
.modalError {
  text-align: left;
}

.modalNetworkError {
  text-align: center;
}