@import 'index-mixins';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 64px auto 0;

  @include mobile {
    flex-direction: column;
    margin: 120px auto 0;
    text-align: center;
  }
}

.dealsHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dealsTitle {
  @include title;

  max-width: 688px;
}

.dealsFrom {
  @include description;

  margin-top: 24px;
}

.dealsDeparture {
  display: flex;
  margin-top: 32px;

  &::-webkit-scrollbar {
    display: none;
  }

  @include mobile {
    position: relative;
    flex-wrap: nowrap;
    justify-content: space-between;
    box-sizing: border-box;
    max-width: 100vw;
    padding: 0 16px;
    overflow-x: auto;
  }
}

.dealsGallery {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100vw;
  margin-top: 16px;
  padding: 0 80px;
  overflow-x: auto;
  overflow-y: hidden;

  @include mobile {
    justify-content: space-between;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.deal {
  position: relative;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: 225px;
  height: 565px;
  overflow: hidden;
  color: white;
  font-family: Inter, sans-serif;
  text-decoration: none;
  text-outline: none;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  transform: rotate(-30deg);
  cursor: pointer;
  animation-name: fade-in;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  & + & {
    margin-left: 29px;

    @include mobile {
      margin-left: 20px;
    }
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all 0.2s ease-in-out;
    content: '';
  }

  &:hover {
    .dealImage {
      transform: rotate(30deg) scale(1.1);
    }

    &::before {
      opacity: 1;
    }
  }

  @include mobile {
    width: 144px;
    height: 362px;
  }
}

.dealSkeleton {
  position: relative;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: 225px;
  height: 565px;
  overflow: hidden;
  text-decoration: none;
  text-outline: none;
  border-radius: 50% !important;
  transform: rotate(-30deg);
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  & + & {
    margin-left: 32px;

    @include mobile {
      margin-left: 20px;
    }
  }

  @include mobile {
    width: 144px;
    height: 362px;
  }
}

.dealButton {
  box-sizing: border-box;
  height: 48px;
  padding: 12px 16px;
  color: white;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  text-decoration: none;
  text-outline: none;
  background: rgba(white, 0.1);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background $durationShort1;

  & + & {
    margin-left: 8px;
  }

  &:hover {
    background: rgba(white, 0.2);
  }

  &.isActive {
    color: black;
    background: white;
    cursor: default;
    pointer-events: none;
  }
}

.dealName {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-weight: 900;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  transform: rotate(30deg);
}

.dealImage {
  height: 100%;
  transform: rotate(30deg);
  transition: all 0.2s ease-in-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
