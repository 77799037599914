@import 'index-mixins';

.root {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;

  form {
    margin: 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  max-width: 1200px;
  padding-top: 244px;
  padding-bottom: 272px;

  @include mobile {
    min-height: 100vh;
    padding-top: 136px;
    padding-bottom: 144px;
  }
}

.anchorLink {
  @include font-large;

  padding: 12px 32px;
  color: var(--color-white);
  text-decoration: none;
}

.message {
  width: auto;
  background-color: rgba(var(--color-white--rgb), 0.1);
  border-radius: 24px;
  cursor: pointer;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  transition: background-color $durationMedium1, transform $durationMedium2,
    opacity $durationMedium2, max-height $durationMedium2, margin-top $durationMedium2,
    margin-bottom $durationMedium2;

  &:hover {
    background-color: rgba(var(--color-white--rgb), 0.2);
  }
}

.messageContainer {
  padding: 0;
}

.caption {
  @include title;

  z-index: 0;
}

.background {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transform: translate(-50%, -50%);
  user-select: none;

  @include mobile {
    display: none;
  }
}

.backgroundMobile {
  display: none;

  @include mobile {
    display: block;
    background-size: auto 100%;
  }
}

.shadow,
.videoBackground {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  user-select: none;
}

.shadow {
  box-shadow: 0 -200px 100px -120px var(--color-black) inset;
}
