@import 'mixins';

.paymentVariants {
  box-shadow: none;
  border-radius: 16px;

  @include mobile {
    padding: 24px;
  }
}


